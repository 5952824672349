
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import { getCookieName, setCookie, mime } from '@/utils/utils';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
@Component({
    name: "DocumentCenter",
    components: {
        SingleHeader,
        SingleFooter,
    },
})
export default class DocumentCenter extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private contentTitle: string = "驱动列表";
    private useInfo: any = {}; // 用户信息
    private docList: any = [];
    private showDialog: boolean = false;
    private anchorIndex: number = 0;
    private visitorId: any = '';
    private whether: boolean = false;
    private mounted() {
        this.getDocList();
        this.allowThirdCookie();
        window.addEventListener("scroll", this.handleAnchorScroll, true);
    }
    private destroyed() {
        window.removeEventListener('scroll', this.handleAnchorScroll, true);
    }
    private handleAnchorScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const classifyArr = this.docList.map((item: any) => item.classify);
        const offsetArr: any = [];
        classifyArr.forEach((element: any) => {
            offsetArr.push(document.getElementById(`anchor${element}`)!.offsetTop);
        });
        if (offsetArr.length) {
            this.whether = scrollTop > offsetArr[0];
            for (let i = offsetArr.length - 1; i >= 0; i--) {
                if (i === 0 && scrollTop < offsetArr[i]) {
                    this.anchorIndex = classifyArr[i];
                } else if (i > 0 && scrollTop >= offsetArr[i - 1] + 100 && scrollTop < offsetArr[i] + 100) {
                    this.anchorIndex = classifyArr[i];
                }
            }
        }
    }
    private async allowThirdCookie() {
        // Get the visitor identifier when you need it.
        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();

        // This is the visitor identifier:
        this.visitorId = result.visitorId.toString();
        this.$httpService.getData({identify: this.visitorId}, `/apiProxy/api/frontend/document/reminder`)
        .then((res: any) => {
            if (res !== true) {
                this.showDialog = true;
            }
        });
    }
    private setAllowCookie() {
        this.$httpService.getData({identify: this.visitorId}, `/apiProxy/api/frontend/document/save/reminder`)
        .then((res: any) => {
            this.showDialog = false;
        });
    }
    private getDocList() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/document`)
        .then((res: any) => {
            for (const key in res) {
                if (Object.prototype.hasOwnProperty.call(res, key)) {
                    const element = res[key];
                    // console.log(key, element);
                    this.docList.push({
                        classify: key,
                        classifyName: element[0].classifyName,
                        items: element,
                    });
                }
            }
            this.$nextTick(() => {
                this.handleAnchorScroll();
            });
        });
    }
    private docDetail(id: any) {
        var api = `/apiProxy/api/frontend/document/${id}`

        //适配工壹号嵌套这个页面
        if (this.$route.fullPath.indexOf('token=') > -1) {
          api += '?token=' + unescape(this.$route.query.token + '');
        }
        this.$httpService.getData({}, api)
        .then((res: any) => {

            //适配工壹号嵌套这个页面,这个接口返回登录信息
            if (res.data && res.data.data && res.data.data.legacyToken) {
              localStorage.setItem('session_token', res.data.data.legacyToken);
              localStorage.setItem('legacy_cookieName', res.data.data.legacyCookieName);
              localStorage.setItem('legacy_cookieDomain', res.data.data.legacyCookieDomain);
              localStorage.setItem('legacy_cookieTimeout', res.data.data.legacyCookieTimeout);
            }

            const routeUrl = this.$router.resolve({
                path: "/documentPage",
                query: { id },
            });
            window.open(routeUrl.href, '_blank');
        }).catch((error: any) => {
            if (error.code === 1002017000) {
                // 未登录
                this.$alert('您的账号未登录，请登录后查看！', '提示', {
                    showClose: true,
                    confirmButtonText: '前往登录',
                    callback: (action: any) => {
                        if (action === 'confirm') {
                            // 清除各种缓存数据
                            localStorage.clear();
                            // 跳转到登录页面
                            this.$router
                            .push({
                                path: '/login',
                                query: {
                                    redirect: window.location.href,
                                },
                            }).catch((err) => err);
                        }
                    },
                });
            }
        });

    }
    private goAnchor(anchorNum: number) {
        document.getElementById(`anchor${anchorNum}`)!.scrollIntoView({
            behavior: "smooth",
        });
        this.anchorIndex = anchorNum;
    }
}
